import FileSaver from 'file-saver';
import * as Session from './session';

const Utils = {
  /**
   * Flat Map 根据相应的字断
   * @param arr 源数组
   * @param step 层级
   * @param clear 是否删除原层级数据
   * @returns {[]|*[]}
   */
  flatMap(arr, step = 'children', clear = false) {
    const flatArr = [];
    const iterate = function(item) {
      flatArr.push(item);
      if(item[step]) {
        item[step].forEach(v => {
          iterate(v);
        });
        if(clear) {
          delete item[step];
        }
      }
    };
    if(!arr || !(arr instanceof Array)) {
      return [];
    } else {
      arr.forEach(iterate);
      return flatArr;
    }
  },

  /**
   * unFlat Map 根据相应的字断
   * @param items 扁平数组
   * @param key 父类id key
   * @param parentId 父类id key
   * @param child 子类 key
   * @returns {[]}
   */
  unFlatMap(items, key = 'id', parentId = 'parentId', child = 'children') {
    const result = [];   // 存放结果集
    const itemMap = {};  //
    for(const item of items) {
      const id = item[key];
      const pid = item[parentId];

      if(!itemMap[id]) {
        itemMap[id] = { [child]: [] };
      }

      itemMap[id] = { ...item, [child]: itemMap[id][child] };

      const treeItem =  itemMap[id];

      if(pid === 0) {
        result.push(treeItem);
      } else {
        if(!itemMap[pid]) {
          itemMap[pid] = { [child]: [] };
        }
        itemMap[pid][child].push(treeItem);
      }
    }
    return result;
  },

  params2json(params = '', slice = '&') {
    return params.split(slice).reduce((acc, item) => {
      let arr = item.split('=');
      return item ? { ...acc, ...{ [arr[0]]: arr[1] }} : acc;
    }, {});
  },

  handlerOptions(option, textKey, valueKey) {
    if(!option) throw new Error('option can not be undifend or null');
    const optionProps = {};
    if(typeof option === 'object') {
      optionProps.disabled = option.disabled || false;
      option = {
        [textKey]: option[textKey],
        [valueKey]: option[valueKey]
      };
    } else {
      option = { [textKey]: option, [valueKey]: option };
    }
    return { option, optionProps };
  },
  getFileExt(fileName = '') {
    const index = fileName.lastIndexOf('.');
    const regExp = new RegExp('^[A-Za-z0-9]+$');
    if(index > 0 && regExp.test(fileName.slice(index + 1))) {
      fileName = fileName.toLowerCase();
      return fileName.substring(index + 1);
    }
    return '';
  },
  // 导出文件
  exportFile(url = '', name = '') {
    if(!url) return;
    return FileSaver.saveAs(url, `${`${name}`.trim()}.${Utils.getFileExt(url)}`);
  },

  isNotEmpty(value) {
    switch (typeof value) {
      case 'undefined': {
        return false;
      }

      case 'string': {
        return value.length !== 0;
      }

      case 'object': {
        if(Array.isArray(value)) {
          return value.length !== 0;
        } else if(value === null) {
          return false;
        } else {
          return Object.keys(value).length !== 0;
        }
      }

      default: {
        return true;
      }
    }
  },

  //保留搜索页
  checkSearchOptions(searchOptions) {
    const pathname = window.location.pathname;
    const sessionKey = `${pathname}-searchOptions`;
    if(Session.get(sessionKey)) {
      return JSON.parse(Session.get(sessionKey));
    } else {
      return Session.put(sessionKey, JSON.stringify(searchOptions));
    }
  },

  //保留搜索页
  /**
   * 传了searchOptions就会存储，不传就是读取
   * @param searchOptions
   * @returns {any|void}
   */
  checkSearchOptions1(searchOptions) {
    const pathname = window.location.pathname;
    const sessionKey = `${pathname}-searchOptions`;
    if(searchOptions) {
      return Session.put(sessionKey, JSON.stringify(searchOptions));
    } else if(Session.get(sessionKey)) {
      return JSON.parse(Session.get(sessionKey || '{}'));
    }
  },

  searchAssign(origin = {}, values = {}, ...params) {
    let tempValue = Object.assign({}, values);
    if(params && params.length) {
      params.forEach((item) => {
        Object.assign(tempValue, item);
      });
    }
    if(Object.keys(tempValue).length) {
      for(let key in tempValue) {
        if(this.isNotEmpty(tempValue[key]) && this.isNotEmpty(String(tempValue[key]))) {
          origin[key] = tempValue[key];
        } else {
          delete origin[key];
        }
      }
    } else {
      for(let key in origin) {
        if(!this.isNotEmpty(origin[key])) {
          delete origin[key];
        }
      }
    }
    return origin;
  },

  /**
   * 先过滤对象中的空属性，再合并，使用方法类似于Object.assign，不对第一个参数做修改，而是将结果作为新的对象返回
   * @param args
   * @returns {{}}
   */
  filterMerge(...args) {
    return Object.assign.apply(null, args.map((object) => {
      return Object.keys(object).reduce((obj, key) => {
        const value = object[key];
        if(Utils.isNotEmpty(value) && value !== 'undefined') {
          obj[key] = value;
        }
        return obj;
      }, {});
    }));
  },

  json2params(json, slice = '&') {
    return Object.keys(json).reduce((acc, item) => {
      return String(acc) + item + '=' + json[item] + slice;
    }, '').slice(0, -1);
  },

  formatQuery2QueryStr(query = {}) {
    let queryStr = '';
    const filteredQuery = Utils.filterMerge(query);
    if(Utils.isNotEmpty(filteredQuery)) {
      queryStr = '?' + Utils.json2params(filteredQuery);
    }
    return queryStr;
  },

  /**
   * 链接拼参数
   * @param url 可以是带query的也可以是不带的
   * @param params 拼的参数对象
   * @returns {string}
   */
  padQuery(url = '', params = {}) {
    const [pathname, queryStr] = url.split('?');
    let tempQuery = Utils.filterMerge(Object.assign({}, Utils.params2json(queryStr), Utils.filterMerge(Object.assign({}, params))));
    let searchQuery = Utils.formatQuery2QueryStr(tempQuery);
    return `${pathname}${searchQuery}`;
  },
  // 权限管理处理数据信息
  handleDataInfo(data = [], type = 1) {
    let list = [];
    if(type === 1) {
      data.forEach(item => {
        const infoFirst = {};
        infoFirst.title = item.name;
        infoFirst.key = item.id;
        if(item.childList && item.childList.length > 0) {
          infoFirst.children = [];
          item.childList.forEach(v => {
            const infoTwo = {};
            infoTwo.title = v.name;
            infoTwo.key = v.id;
            infoFirst.children.push(infoTwo);
          });
        }
        list.push(infoFirst);
      });
    } else {
      data.forEach(item => {
        list.push(item.id);
        if(item.childList && item.childList.length > 0) {
          item.childList.forEach(v => {
            list.push(v.id);
          });
        }
      });
    }
    return list;
  },
  getDate(time) {
    if(time && !(+time > 0)) {
      let [tDate, tTime] = time.split(' ');
      tDate = tDate.replace(/[^\d]/g, '/');
      time = tTime ? `${tDate} ${tTime}` : `${tDate}`;
    }
    const d = time ? new Date(time) : new Date();
    const M = d.getMonth() + 1;
    const D = d.getDate();
    const h = d.getHours();
    const m = d.getMinutes();
    const s = d.getSeconds();
    return {
      Y: d.getFullYear(),
      M: M < 10 ? '0' + M : M,
      W: d.getDay(), //周几
      D: D < 10 ? '0' + D : D,
      h: h < 10 ? '0' + h : h,
      min: m < 10 ? '0' + m : m,
      s: s < 10 ? '0' + s : s,
      ms: d.getMilliseconds()
    };
  },
  getSizeByByte(size) {
    if(typeof size !== 'number') {
      size = Number(size);
      // throw Error("Argument Must Be A Number")
    }
    const KBUNIT = 'KB', MBUNIT = 'MB', UNITSIZE = 1024;
    let kb = size / UNITSIZE,
      mb = size / (UNITSIZE * UNITSIZE);
    return mb > 0.01
      ? parseFloat(mb).toFixed(2) + MBUNIT
      : parseFloat(kb).toFixed(2) + KBUNIT;
    // return parseFloat(mb).toFixed(2) + MBUNIT;
  },
  /**
   * 三位数内的阿拉伯数字转换
   * @param num
   * @returns {string}
   */
  numberConvertToUppercase(num) {
    num = Number(num);
    let upperCaseNumber = ['零', '一', '二', '三', '四', '五', '六', '七', '八', '九', '十', '百', '千', '万', '亿'];
    let length = String(num).length;
    if(length === 1) {
      return upperCaseNumber[num];
    } else if(length === 2) {
      if(num === 10) {
        return upperCaseNumber[num];
      } else if(num > 10 && num < 20) {
        return '十' + upperCaseNumber[String(num).charAt(1)];
      } else {
        return upperCaseNumber[String(num).charAt(0)] + '十' + upperCaseNumber[String(num).charAt(1)].replace('零', '');
      }
    }
  },

  /**
   *1.为处理价格调用，不需要传len参数
   * 2.处理数字位数，必须传len
   */
  toFixed(num = 0, len = 0) {
    let result = '',
      level = 3,
      [tempNum, tail] = `${num}`.split('.'),
      showText = false;
    if(num >= 1000000) {
      showText = true;
      let tempCount = (num / 10000).toFixed(2);
      [tempNum, tail] = `${tempCount}`.split('.');
    }
    const padEnd = (str, len = 2, padString = 0) => {
      str = str.slice(0, len);
      while(str.length < len) {
        str += padString;
      }
      return str;
    };
    if(!len) {
      tail = tail ? padEnd(tail) : '00';
      tempNum = (tempNum || 0).toString();
      while(tempNum.length > level) {
        result = ',' + tempNum.slice(-level) + result;
        tempNum = tempNum.slice(0, tempNum.length - level);
      }
      if(tempNum) {
        result = tempNum + result;
      }
      return `${result}.${tail}${showText ? '万' : ''}`;
    } else {
      if(typeof num !== 'number') {
        num = Number(num);
      }
      return num.toFixed(len);
    }
  },
  // 配置二维码扫码链接
  getAppUrl(itemType, id, basePath, commonQuerys, sceneCode) {
    let middlePath = '';
    if(itemType === 'KK') {
      return `*kk${basePath}A${id}/questionnaire${commonQuerys}`;
    } else if(
      itemType === 'LIVE' ||
      itemType === 'LIVE_TIMETABLE' ||
      itemType === 'SCHEDULE'
    ) {
      // return `${basePath}application/${id}/live${commonQuerys}`;
      return `*live${basePath}A${id}/liveinfo${commonQuerys}`;
    } else if(itemType === 'ACTIVITY') {
      return `${basePath}application/${id}/propaganda${commonQuerys}`;
    } else if(itemType === 'NAIRE' || itemType === 'DA') {
      return `*qn${basePath}A${id}/list${commonQuerys}`;
    } else if(itemType === 'SPECIAL') {
      return `*st${basePath}A${id}/special${commonQuerys}`;
    } else if(itemType === 'VOTE') {
      return `*vt${basePath}A${id}/${Date.now()}/list${commonQuerys}`;
    } else if(itemType === 'BM') {
      return `*mt${basePath}A${id}/summary${commonQuerys}`;
    } else if(itemType === 'RECITE_WORD') {
      switch (sceneCode) {
        //消消乐
        case 'LINK':
          middlePath = 'blocks';
          break;
        default:
          middlePath = 'list';
      }
      return `*wd${basePath}A${id}/${middlePath}${commonQuerys}`;
    } else if(itemType === 'EF') {
      return `*tb${basePath}A${id}/list${commonQuerys}`;
    } else if(itemType === 'TUTOR') {
      return `*cc${basePath}A${id}/list${commonQuerys}`;
    } else if(itemType === 'ITEM_BANK') {
      return `*pretest${basePath}A${id}/list${commonQuerys}`;
    } else if(itemType === 'GROUP') {
      return `*group${basePath}A${id}/info${commonQuerys}`;
    } else if(itemType === 'ZSCORE') {
      return `*zscore${basePath}A${id}/search${commonQuerys}`;
    } else if(itemType === 'VOICE_MSG') {
      return `${basePath}application/${id}/voicemail${commonQuerys}`;
    } else if(itemType === 'BOOKCARD') {
      return `*card${basePath}A${id}/home${commonQuerys}`;
    } else if(itemType === 'INTERACT') {
      return `*interact${basePath}A${id}/home${commonQuerys}`;
    } else if(itemType === 'CLOCK') {
      return `*clock${basePath}A${id}/custom/sign${commonQuerys}`;
    } else if(itemType === 'AUDIO_MAGIC') {
      return `*magic${basePath}A${id}/box${commonQuerys}`;
    } else if(itemType === 'COURSE_WARE') {
      return `*cw${basePath}A${id}/list${commonQuerys}`;
    } else if(itemType === 'WORD_DICTATION') {
      return `*dictation${basePath}A${id}/list${commonQuerys}`;
    } else if(itemType === 'MATCH_LISTEN') {
      return `*mv${basePath}A${id}/listen${commonQuerys}`;
    } else if(itemType === 'ARTICLE_READING') {
      return `*ar${basePath}A${id}/reading${commonQuerys}`;
    } else if(itemType === 'ENGLISH_WALKMAN') {
      return `*wm${basePath}A${id}/player${commonQuerys}`;
    } else if(itemType === 'PBSTORY') {
      return `*pbstory${basePath}A${id}/list${commonQuerys}`;
    } else if(itemType === 'ORAL_EVALUATION') {
      return `*speak${basePath}A${id}/info${commonQuerys}`;
    } else if(itemType === 'TEST') {
      switch (sceneCode) {
        //属性测试
        case 'TEST_CHARACTER':
          middlePath = 'char';
          break;
        //知识测试
        case 'TEST_KNOWLEDGE':
          middlePath = 'know';
          break;
        //情感测试
        case 'TEST_EMOTION':
          middlePath = 'emotion';
          break;
        case 'TEST_ACTIVITY':
          middlePath = 'activity';
          break;
        case 'TEST_DIFFERENCE':
          middlePath = 'findDiff';
          break;
        case 'TEST_LOGIC':
          middlePath = 'gamebook';
          break;
        default:
          middlePath = 'char';
      }
      return `*tt${basePath}A${id}/${middlePath}/info${commonQuerys}`;
    } else if(itemType === 'WISH') {
      return `*wish${basePath}A${id}/home${commonQuerys}`;
    } else if(itemType === 'DRAW') {
      // 抽奖新类型
      let routeName = 'luckPage';
      if(sceneCode === 'TASK') {
        routeName = 'lucktask';
      }
      return `*luck${basePath}A${id}/${routeName}${commonQuerys}`;
    } else if(itemType === 'VIDEO') {
      return `${basePath}application/${id}/video${commonQuerys}`;
    } else if(itemType === 'EBOOK') {
      return `${basePath}application/${id}/ebook${commonQuerys}`;
    } else if(itemType === 'AUDIO') {
      switch (sceneCode) {
        // 绘本配乐
        case 'PAINTING':
          return `${basePath}application/${id}/audio/painting${commonQuerys}`;
        // 绘本配乐
        case 'LISTEN':
          return `${basePath}application/${id}/audio/listen${commonQuerys}`;
        // 绘本配乐
        case 'LESSON':
          return `${basePath}application/${id}/audio/lesson${commonQuerys}`;
        default:
          return `${basePath}application/${id}/audio${commonQuerys}&appType=${itemType}`;
      }
    } else if(itemType === 'TEACH_RESOURCE') {
      return `*tr${basePath}A${id}/packagelist${commonQuerys}`;
    } else if(itemType === 'TEST_PAPER') {
      return `${basePath}application/${id}/testpaper/paperList/${commonQuerys}`;
    } else if(itemType === 'STROKE_ORDER') {
      return `*pen${basePath}A${id}/list${commonQuerys}`;
    } else if(itemType === 'MEMBER') {
      return `*member${basePath}A${id}/info${commonQuerys}`;
    } else if(itemType === 'SUBJECTNOTE') {
      return `*subject${basePath}A${id}${commonQuerys}`;
    } else if(itemType === 'BOOK_CLICK') {
      return `*clickread${basePath}A${id}${commonQuerys}`;
    } else if(itemType === 'FLAG') {
      return `*flag${basePath}A${id}/entryFlag/start${commonQuerys}`;
    } else if(itemType === 'COLD_KNOWLEDGE') {
      return `*cold${basePath}A${id}/entryCold/start${commonQuerys}`;
    } else if(itemType === 'TICKET') {
      return `*aticket${basePath}A${id}/ticketHome${commonQuerys}`;
    } else if(itemType === 'FOREIGN_READ') {
      return `*getti${basePath}A${id}/gettiHome${commonQuerys}`;
    } else if(itemType === 'ARITHMETIC') {
      return `*arithmetic${basePath}A${id}/home${commonQuerys}`;
    } else if(itemType === 'BOOK_ORDER') {
      return `${basePath}application/${id}/bookOrder${commonQuerys}`;
    } else if(itemType === 'BOOK_ORDER') {
      return `${basePath}application/${id}/bookOrder${commonQuerys}`;
    } else if(['PHOTOGRAPH', 'AI_TIKU', 'APPLET_CLOCK', 'APPLET_QA', 'APPLET_WORD'].includes(itemType)) {
      return `/rayh5/applet?type=${itemType}`;
    } else if(itemType === 'MEMBER_ACTIVITY') {
      return `/rayh5/member/detail?activityId=${id}&sceneId=`;
    } else if(itemType === 'JIGSAW') {
      return `*jigsaw${basePath}A${id}/${middlePath}home${commonQuerys}`;
    } else if(itemType === 'POSTGRADUATE_CALC') {
      return `*ky${basePath}A${id}/home${commonQuerys}`;
    } else if(itemType === 'GRAFFITI') {
      return `*graffiti${basePath}A${id}/${middlePath}home${commonQuerys}`;
    } else if(itemType === 'MOUNTING_MASTER') {
      return `*rahmen${basePath}A${id}/home${commonQuerys}`;
    } else if(itemType === 'POETRY') {
      return `*poetry${basePath}A${id}/home${commonQuerys}`;
    } else {
      return `${basePath}application/${id}/display${commonQuerys}&appType=${itemType}`;
    }
  },
  getProductUrl(itemType, id, basePath, commonQuerys) {
    if(itemType === 'PRETEST') {
      return `*pretest${basePath}P${id}/list${commonQuerys}`;
    } else if(['LIVE', 'LIVE_TIMETABLE', 'SCHEDULE'].includes(itemType)) {
      return Utils.padQuery(`*live${basePath}A${id}/liveinfo${commonQuerys}`, {
        proType: 'SCHEDULE'
      });
    } else if(itemType === 'QA') {
      return `${basePath}application/-1/foranswer/select/${id}${commonQuerys}`;
    } else if(itemType === 'SUBJECTNOTE') {
      return `*subject${basePath}P${id}${commonQuerys}`;
    } else if(itemType === 'ATTENDANCE_TASK') {
      return `*clock${basePath}P${id}${commonQuerys}`;
    } else if(itemType === 'VIDEO_SCHEDULE') {
      return `${basePath}product/display/${id}/video/preview${commonQuerys}`;
    } else {
      return `${basePath}product/display/${id}${commonQuerys}`;
    }
  },
  getBeforeOneDay(step = 30) {
    // step--;
    let myDate = new Date();
    myDate.setDate(myDate.getDate() - step);
    const Y = myDate.getFullYear();
    const M = myDate.getMonth();
    const D = myDate.getDate();
    return { M, D, Y, timeStamp: +myDate };
  },
  pageContentScrollTop() {
    const appPageBody = document.getElementById('appPageBody');
    if(appPageBody) {
      appPageBody.scrollIntoView(true);
    }
  }
};

export default Utils;

